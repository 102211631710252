
import { Component, Vue, Mixins, Watch } from "vue-property-decorator";
// api
import {
  allRole,
  createRole,
  roleUpdate,
  deleteRoles,
  roleUpdateStatus,
  allocMenu,
  allocResource,
  allRoleList,
  roleMenuList,
  roleMenuResource,
} from "@/api/admin";
import { getResourceAll, getResourceCategoryAll } from "@/api/resource";
import { Role } from "@/types/api/admin";
import { Resource, ResourceCategory } from "@/types/api/resource";
import { treeList } from "@/api/menu";
import { Menu } from "@/types/api/menu";
// 混入
import Loading from "@/mixins/loading";
import TablePagination from "@/mixins/tablePagination";
// 工具
import print from "@/utils/print";
import debounce from "@/utils/debounce";
import copy from "@/utils/deepCopy";

@Component
export default class Admin extends Mixins(Loading, TablePagination) {
  private tableData: Array<Role> = [];
  // 初始化表单
  initForm: Role = {
    createTime: "",
    adminCount: 0,
    description: "",
    name: "",
    sort: 0,
    id: 0,
    status: true,
  };
  form: Role = this.initForm;
  type: string = "add"; // add:添加  edit: 编辑
  dialogVisible: boolean = false;
  dialogVisibleTree: boolean = false;
  dialogVisibleResource: boolean = false;
  keyword: string = ""; // 搜索关键字
  defaultProps: any = {
    children: "children",
    label: "title",
  };
  resourceList: any = []; // 资源列表
  // 树形菜单
  menuList: Array<any> = [];
  isIndeterminate: boolean = true;
  resourceIndex: number = 0; // 选中的资源下标
  roleId: number = 0; // 角色id
  menuIds: Array<number> = []; // 被选中的菜单

  /**
   * 生命周期
   */
  mounted() {
    this.getData();
    this.getAllResource();
    this.getTreeList();
  }
  /**
   * 监听
   */
  @Watch("keyword")
  searchValue() {
    debounce(() => this.getData());
  }

  /**
   * methods
   */
  // 每页每页条数
  getData() {
    this.showLoading();
    allRoleList({
      keyword: this.keyword,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    }).then((res: any) => {
      res.data.list.forEach((e: Role) => {
        e.status = e.status == 0 ? false : true;
      });
      this.total = res.data.total;
      this.tableData = res.data.list;
      this.hideLoading();
    });
  }
  // 获取树形菜单
  getTreeList() {
    treeList().then((res: any) => {
      this.menuList = res.data;
    });
  }
  // 获取资源列表
  async getAllResource() {
    let list: any, resou: any;
    list = await getResourceCategoryAll(); // 资源分类
    resou = await getResourceAll(); // 资源列表
    resou = resou.data;
    list.data.forEach((e: any) => {
      e.data = [];
      e.checkAll = false;
      e.checked = [];
      for (const k in resou) {
        // 匹配分类
        if (e.id == resou[k].categoryId) {
          e.data.push(resou[k]);
        }
      }
    });
    this.resourceList = list.data.filter((e: any) => e.data.length > 0); // 过滤掉空的分类
  }
  // 资源全选
  handleCheckAllChange(val: boolean, index: number) {
    this.resourceIndex = index;
    this.resourceList[index].checked = val
      ? this.resourceList[index].data.map((e: any) => e.id)
      : [];
    this.isIndeterminate = false;
  }
  // 资源选择单项
  handleCheckedCitiesChange(value: any, index: number) {
    this.resourceIndex = index;
    let checkedCount = value.length;
    this.resourceList[index].checkAll =
      checkedCount === this.resourceList[index].data.length;
    this.isIndeterminate =
      checkedCount > 0 && checkedCount < this.resourceList[index].data.length;
  }
  // 分配资源
  handleResource(row: Role): void {
    roleMenuResource(row.id).then((res: any) => {
      this.resourceList.forEach((e: any) => {
        e.checked = [];
        res.data.map((o: any) => {
          e.data.map((m: any) => {
            if (o.id == m.id) {
              e.checked.push(o.id);
            }
          });
        });
        if (e.data.length == e.checked.length) {
          e.checkAll = true;
        } else {
          e.checkAll = false;
        }
      });
    });
    this.roleId = row.id;
    this.dialogVisibleResource = true;
  }
  // 添加
  handleAdd() {
    this.type = "add";
    this.dialogVisible = true;
    this.form = JSON.parse(JSON.stringify(this.initForm)); // 深拷贝初始化
  }
  // 编辑
  handleEdit(row: Role) {
    this.type = "edit";
    this.dialogVisible = true;
    this.form = JSON.parse(JSON.stringify(row));
  }
  // 删除
  handleDelete(id: number) {
    this.$confirm(`是否要删除该用户 (id:${id}) ?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      deleteRoles({ ids: [id].toString() }).then((res: any) => {
        this.$message.success("删除成功");
        this.getData();
      });
    });
  }
  // 确认添加/修改
  async confirm() {
    let promi: any;
    this.form.status = this.form.status ? 1 : 0;
    print(this.form);
    if (this.type == "add") {
      promi = await createRole(this.form);
    } else {
      promi = await roleUpdate(this.form);
    }
    if (promi.code == 200) {
      this.$message.success(this.type == "add" ? "添加成功" : "修改成功");
      this.form = copy(this.initForm);
      this.dialogVisible = false;
      this.getData();
    }
  }
  // 修改状态
  updateStatus(row: Role) {
    row.status = row.status ? 1 : 0;
    roleUpdate(row).then((res: any) => {
      this.$message.success("修改成功");
      this.getData();
    });
  }
  // 触发分配菜单
  handleMenu(row: Role) {
    this.roleId = row.id;
    roleMenuList(row.id).then((res: any) => {
      this.menuIds = res.data.map((e: any) => {
        if (e.parentId == 0 && this.hasSubmenu(res.data, e.id)) {
          return null;
        }
        return e.id.toString()
      });
      let tree: any = this.$refs.tree;
      tree.setCheckedKeys(this.menuIds);
    });
    this.dialogVisibleTree = true;
  }

  hasSubmenu(data, id) {
    for (let item of data) {
      if (item.parentId == id) {
        return true;
      }
    }
    return false;
  }

  // 关闭分配菜单对话框
  closeTreeDialog() {
    let tree: any = this.$refs.tree;
    tree.setCheckedKeys([]);
    this.dialogVisibleTree = false;
  }
  // 获取被选中的节点树
  // getCheckedNode(val: Resource) {
  //   print("选中的id：" + val.id);
  //   print(this.menuIds);
  //   if (this.menuIds.includes(val.id)) {
  //     let index = this.menuIds.findIndex((f: number) => f == val.id);
  //     this.menuIds.splice(index, 1);
  //   } else {
  //     this.menuIds.push(val.id);
  //   }
  // }
  // 确认分配菜单
  confirmAllocMenu() {
    let tree: any = this.$refs.tree;
    let ids: Array<Menu> = tree.getCheckedNodes(false, true);
    this.menuIds = ids.map((m: Menu) => m.id);
    allocMenu({
      menuIds: this.menuIds.toString(),
      roleId: this.roleId,
    }).then((res: any) => {
      this.dialogVisibleTree = false;
      this.$message.success("设置成功");
      this.getData();
    });
  }
  // 确认分配资源
  confirmAllocResource() {
    allocResource({
      resourceIds: this.resourceList[this.resourceIndex].checked.toString(),
      roleId: this.roleId,
    }).then((res: any) => {
      this.dialogVisibleResource = false;
      this.$message.success("设置成功");
      this.getData();
    });
  }
}
