const print = function (...args: any) {
  if (arguments.length < 1) return console.log(" ")
  try {
    for (const key in arguments) {
      if (arguments) { // 确保传值不为null或者undefined
        console.log(JSON.parse(JSON.stringify(arguments[key])))  // 打印序列化处理后的内容
      } else {
        console.error(arguments)
      }
    }
  } catch (error) {
    console.error(error) // 原始方法打印
  }
}

export default print